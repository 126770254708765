@import "../../../assets/mixins.scss";

section.home {
    position: relative;
    padding-top: 0.58rem;
    padding-bottom: 0.48rem;
    text-align: center;

    @include m {
        padding-top: 0.24rem;
        padding-bottom: 0.24rem;
    }

    .title {
        font-weight: 700;
        font-size: 0.48rem;
        line-height: 1.2em;
        letter-spacing: 0.04em;
        color: #ffffff;

        @include m {
            font-size: 0.24rem;
        }
    }

    .subtitle {
        font-weight: 400;
        font-size: 0.32rem;
        line-height: 1.2em;
        letter-spacing: 0.04em;
        color: #f1c51c;

        @include m {
            font-size: 0.16rem;
        }
    }

    * + .subtitle {
        margin-top: 0.24rem;

        @include m {
            margin-top: 0.16rem;
        }
    }

    .image {
        width: 6.8rem;
        max-width: 100%;
    }

    * + .image {
        margin-top: 0.58rem;

        @include m {
            margin-top: 0.24rem;
        }
    }

    .actions {
        .btn-store {
        }
    }

    * + .actions {
        margin-top: 0.48rem;

        @include m {
            margin-top: 0.24rem;
        }
    }
}
