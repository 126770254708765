@import "../../assets/mixins.scss";
@import "../../assets/shared.scss";

header {
    display: flex;
    padding: 0.24rem 0;
    text-align: center;
    background-color: white;

    @include m {
        border-radius: 0 0 0.24rem 0.24rem;
    }

    img {
        width: 1.56rem;

        @include m {
            width: 1.33rem;
        }
    }
}

footer {
    position: relative;
    padding: 0.64rem 0;
    background-color: white;
    border-radius: 0.24rem 0.24rem 0 0;

    @include m {
        padding: 0.08rem 0 0.24rem 0;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include m {
            flex-direction: column;
        }

        .logo {
            width: 1.56rem;
        }

        .menu {
            @include m {
                width: 100%;
            }

            .menu-item {
                font-weight: 600;
                font-size: 0.18rem;
                line-height: 1.2em;
                color: #49196c;

                @include m {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0.16rem;
                    font-size: 0.16rem;
                    border-bottom: 1px solid #34104f;
                }

                & + .menu-item {
                    @include d {
                        margin-left: 0.48rem;
                    }
                }

                .arrow {
                    width: 0.24rem;
                }
            }
        }

        .social {
            @include m {
                margin-top: 0.24rem;
                text-align: center;
            }

            .social-title {
                margin-bottom: 0.16rem;
                font-weight: 500;
                font-size: 0.16rem;
                line-height: 1.2em;
                color: #34104f;
            }

            .social-items {
                display: flex;
                align-items: center;

                .social-item {
                    display: inline-block;
                    width: 0.4rem;

                    @include m {
                        width: 0.64rem;
                    }

                    & + .social-item {
                        margin-left: 0.24rem;

                        @include m {
                            margin-left: 0.4rem;
                        }
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .bottom {
        .copyright {
            text-align: right;
            font-weight: 500;
            font-size: 0.16rem;
            line-height: 1.2em;
            color: #9e82ba;

            @include m {
                text-align: center;
            }
        }
    }

    * + .bottom {
        margin-top: 0.24rem;

        @include d {
            padding-top: 0.24rem;
            border-top: 1px solid #45355c;
        }
    }
}
