@import "mixins.scss";

@include m {
    .desktop-only {
        display: none !important;
    }
}

@include d {
    .mobile-only {
        display: none !important;
    }
}

html {
    font-family: "Outfit", sans-serif !important;
    font-size: 100px;

    @include m {
        font-size: 26.66666vw;
    }
}

body {
    font-size: 0 !important;
    background-color: #45355C !important;
}

.container {
    position: relative;
    width: 12.64rem;
    max-width: 100%;
    padding-left: 0.32rem;
    padding-right: 0.32rem;
    margin-left: auto;
    margin-right: auto;

    @include m {
        padding-left: 0.24rem;
        padding-right: 0.24rem;
    }
}

/* Buttons */

/* The End */