@import "../../../assets/mixins.scss";

.btn-store {
    display: inline-flex;
    align-items: center;
    height: 0.64rem;
    padding: 0 0.36rem;
    background-color: white;
    border-radius: 0.32rem;

    @include m {
        justify-content: center;
        width: 100%;
        height: 0.48rem;
        padding: 0 0.32rem;
        text-align: center;
    }

    img {
        width: 0.24rem;
    }

    .content {
        text-align: left;

        @include m {
            margin-right: 0.16rem;
        }

        .pre {
            font-weight: 600;
            font-size: 0.12rem;
            line-height: 1.1em;
            color: #34104f;

            @include m {
                font-size: 0.1rem;
            }
        }

        .title {
            font-weight: 600;
            font-size: 0.24rem;
            line-height: 1.1em;
            color: #34104f;

            @include m {
                font-size: 0.18rem;
            }
        }
    }

    * + .content {
        margin-left: 0.16rem;
    }
}

.btn-store + .btn-store {
    @include d {
        margin-left: 0.24rem;
    }

    @include m {
        margin-top: 0.24rem;
    }
}
