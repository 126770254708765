@import "../../../assets/mixins.scss";

section.content {
    position: relative;
    padding-top: 0.48rem;
    padding-bottom: 0.48rem;
    text-align: center;

    .text {
        width: 6rem;
        max-width: 100%;
        margin: 0 auto;
        font-weight: 400;
        font-size: 0.16rem;
        line-height: 1.4em;
        letter-spacing: 0.04em;
        color: #ffffff;

        @include m {
            font-size: 0.14rem;
        }

        a {
            color: inherit;
        }

        b {
            font-size: 0.24rem !important;

            @include m {
                font-size: 0.2rem !important;
            }
        }

        br {
            display: block;
            margin-top: 0.16rem;
        }
    }
}
